export const timeZone = {
    methods: {
        convertBrouserTZ(date) {
            const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const o = new Intl.DateTimeFormat("en" , {
                timeStyle: "medium",
                dateStyle: "short",
                hour12: false
            });

            return o.format(new Date(this.convertBrouserTZobj(date).toLocaleString("en-US", {timeZone: tzString})));   
        },
        convertBrouserTZDateOnly(date) {
            const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const o = new Intl.DateTimeFormat("en" , {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour12: false
            });

            return o.format(new Date(this.convertBrouserTZobj(date).toLocaleString("en-US", {timeZone: tzString})));   
        },
        convertBrouserTZobj(date) {
            const dateObj = new Date(date);
            
            const dateObjUtc = new Date(Date.UTC(
                dateObj.getFullYear(), 
                dateObj.getMonth(), 
                dateObj.getDate(), 
                dateObj.getHours(), 
                dateObj.getMinutes(), 
                dateObj.getSeconds()
            ));
            
            return dateObjUtc;
        }
    }
};