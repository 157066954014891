export const orderProcessStages = {
    data() {
        return {
            stages: {
                pending: 0,
                inProcess: 1,
                paused: 2,
                canceled: 3,
                done: 4,
                notPaid: 5,
                discussign: 6,
                deleted: 7
            },
            stageTextArr: [
                "Pending",
                "In Process",
                "Paused",
                "Canceled",
                "Completed",
                "Not Paid",
                "Pre-cancel",
                "Deleted"
            ]
        }
    }
};